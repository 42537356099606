body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* swiper css */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}
 .swiper-button-next {
  font-size: 5px !important;
  color: rgba(12, 12, 19, 0.756) !important;
  width: 30px !important;
  height: 30px !important;
  background-color: rgba(178, 179, 155, 0.8);
  border-radius: 50%; 
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; 
  box-sizing: content-box;
  position: relative;

}
.swiper-button-prev{
  display: none !important;

}
 .swiper-button-next::after {
  font-size: 20px !important; 
  color: rgba(12, 12, 19, 0.756); 
}

.swiper-pagination {
  display: none !important;
}
.swiper-pagination-bullet {
  display: none !important;
 
}
.swiper-container {
  padding: 10px;
  overflow: hidden !important; 
  position: relative; 
}
.swiper-scrollbar {
  height: 6px !important;
  display: none;
 
}

.swiper-scrollbar-drag {
  display: none;
  width: 100px !important;
  background-color: #828c85 !important; 
  border-radius: 4px !important;
  height: 6px !important; 
  margin-top: 10px !important;
}

